<template>
	<div
		class="flex-column full-height"
	>
		<div
			class="bg-title"
		>
			<button
				:title="program.name"
				@click="toBack()"
			><v-icon large class="">mdi-chevron-left</v-icon><span class=" font-weight-bold size-em-15 vertical-middle">{{ program.name }}</span></button>
		</div>

		<div
			class="pa-20 flex-column full-height overflow-y-auto"
		>
			<div
				v-if="false"
				class="mt-10"
			>
				<h6>대리점 코드</h6>
				<div
					class="box pa-5 bg-gray-light"
				>{{ item.admin_code}} </div>
			</div>
			<template
				v-if="is_nice"
			>
				<div
					class="mt-10"
				>
					<h6>아이디</h6>
					<input
						v-model="item.member_id"
						type="text" placeholder="아이디를 입력하세요"
						class="mt-10 pa-10 input-underline"
						:disabled="isCheckId"
						:rules="[rules.id(item, 'member_id', { min: 6, max: 25})]"
					/>
				</div>

				<div
					class="mt-10"
				>
					<h6>비밀번호</h6>
					<input
						v-model="item.member_password"
						type="password" placeholder="영문/숫자/특수문자 포함 8~20자리"
						class="pa-10 input-underline"
						maxlength="50"
					/>
					<div
						v-if="this.txtValidCheckPass != ''"

						class="mt-10 text-right color-red"
					>{{ this.txtValidCheckPass }}</div>
				</div>

				<div
					class="mt-10"
				>
					<h6>비밀번호 확인</h6>
					<input
						v-model="item.member_password_confirm"
						type="password" placeholder="비밀번호를 한번 더 입력하세요"
						class="pa-10 input-underline"
						maxlength="50"
					/>
					<div
						v-if="item.member_password_confirm && item.member_password && (item.member_password_confirm != item.member_password)"

						class="mt-10 text-right color-red"
					>비밀번호를 확인하세요</div>
				</div>

				<div
					class=" mt-10"
				>
					<h6>이름</h6>
					<div
						class="box pa-5"
					>{{ item.member_name }}</div>
				</div>

				<div
					class=" mt-10"
				>
					<h6>생년월일</h6>

					<div
						class="box pa-5"
					>{{ item.birthdate}}</div>
				</div>

				<div
					v-if="false"
					class="mt-10"
				>
					<h6>주소</h6>
					<div
						class="mt-10 flex-row"
					>
						<input
							v-model="item.member_post"
							type="number" placeholder="우편번호"
							class="input-box flex-2"
							readonly

							:rules="[rules.numeric(item, 'member_post', 10)]"
						/>
						<button
							class="btn btn-blue flex-1"
							@click="daumPost('default')"
						>주소 검색</button>
					</div>
					<div
						class="mt-10"
					>
						<input
							v-model="item.member_addr1"
							type="text" placeholder="기본 주소"
							class="input-box"
							readonly
							maxlength="80"
						/>
					</div>
					<div
						class="mt-10"
					>
						<input
							v-model="item.member_addr2"
							type="text" placeholder="상세 주소를 입력하세요"
							class="input-box"
							maxlength="50"
						/>

					</div>
				</div>

				<div
					class="mt-10"
				>
					<h6>휴대폰 번호</h6>
					<div class="box pa-5 flex-row justify-space-between">
						{{ item.member_tell }}
						<div
							v-if="false"
							class="flex-1 under-line-ddd"
						>
							<button
								v-if="!item.is_on_sms_confirm"
								@click="postSmsAuth"
								class="btn btn-primary"
								:disabled="is_sms_auth"
							>인증요청</button>
							<button
								v-else
								@click="resetSmsAuth"
								class="btn btn-primary"
							>다시하기</button>
						</div>
					</div>
				</div>

				<div
					class="mt-10"
					v-if="item.is_on_sms_confirm && false"
				>
					<h6>SMS 인증</h6>
					<div
						v-if="!item.is_sms_confirm"
						class="flex-row justify-space-between"
					>
						<div class="flex-2">
							<input
								v-model="item.sms_code"
								type="number" placeholder="인증코드를 입력하세요"
								class="input-underline"
								:rules="[rules.max(item, 'sms_code', 6)]"
							/>
						</div>
						<div class="flex-1">
							<button
								@click="postSmsConfirm"
								class="btn btn-primary"
							>인증확인</button>
						</div>
					</div>
					<div
						v-else
						class="input-underline color-success"
					>
						인증이 완료되었습니다.
					</div>
				</div>

				<div
					v-if="false"
					class="mt-10"
				>
					<h6>이메일</h6>
					<input
						v-model="item.member_email"
						type="text" placeholder="이메일을 입력하세요"
						class="input-underline"
						maxlength="50"
					/>
					<div
						v-if="this.txtValidCheckEmail != ''"

						class="mt-10 text-right color-red"
					>{{ this.txtValidCheckEmail }}</div>
				</div>
			</template>
			<div
				v-else
				class="full-height"
			>


				<div>
					<h6>이용약관 및 개인정보 취급방침</h6>
					<div
						class="mt-10 input-box overflow-y-auto"
						style="max-height: 250px"
						:inner-html.prop="mall_info.site_temrs | nl2br"
					></div>

					<div
						class="mt-10 mb-30"
						@click="item.is_agree = !item.is_agree"
					>
						<v-icon
							v-if="item.is_agree"
							class="color-green"
						>mdi mdi-checkbox-marked</v-icon>
						<v-icon
							v-else
						>mdi mdi-checkbox-blank-outline</v-icon>

						이용약관 동의
					</div>
					<div
						class="mt-10 input-box overflow-y-auto"
						style="max-height: 250px"
						:inner-html.prop="mall_info.site_personal | nl2br"
					></div>
					<div
						class="mt-10 mb-30"
						@click="item.is_personal = !item.is_personal"
					>
						<v-icon
							v-if="item.is_personal"
							class="color-green"
						>mdi mdi-checkbox-marked</v-icon>
						<v-icon
							v-else
						>mdi mdi-checkbox-blank-outline</v-icon>

						개인정보 처리방침 동의
					</div>
				</div>
			</div>
		</div>

		<div
			class="bottom"
		>
			<button
				v-if="is_nice"
				:disabled="!isNext"
				class="btn btn-identify"
				@click="next"
			>다음</button>

			<button
				v-else
				class="btn btn-primary"
				@click="onNice"
				:disabled="!item.is_personal || item.is_terms"
			>본인 인증</button>
		</div>

		<DaumPost
			:overlay="daumPostUp"
			@callBack="addPost"
		></DaumPost>

		<div
			v-if="is_on_nice"
			class="position-fixed-full"
		>

			<div
				style="position: absolute; width: 100%; height: 100%; background-color: black; opacity: 0.5"
			></div>
			<div
				style="position: relative; height: 100%; background: none; display: flex; flex-direction: column; justify-content: center; z-index: 9999"
				class="color-white text-center size-px-24"
			>

				<iframe
					v-show="true"
					:src="nice_url"
					class="full-height full-width"
				></iframe>
			</div>
		</div>
	</div>
</template>


<script>

import DaumPost from '@/components/Daum/DaumPost'
import {Base64} from "js-base64";

export default{
	name: 'AppLogin'
	,props: ['Axios', 'codes', 'rules', 'mall_info', 'shop_info']
	, components: { DaumPost }
	,data: function(){
		return {
			program: {
				name: '회원가입'
				, code: 'join'
				, top: false
				, title: true
				, bottom: false
				, class: 'white'
				, wrap: 'join'
				, callBack: {
					name: 'info'
					,event: 'prev'
				}
			}
			,search: {
				COMPFRNAME: ''
			}
			,daumPostUp: false
			,txtValidCheckPass: ''
			,txtValidCheckEmail: ''
			,txtValidCheckCompany: ''
			,item: {
				member_id: ''
				, member_email: ''
				, admin_code: this.$route.params.code ? this.$route.params.code : Base64.encode(this.shop_info.seller_id)
				, is_agree: false
				, sms_confirm: false
				, member_tell: ''
				, sms_code: ''
				, is_use_sms_auth: true
				, is_on_sms_confirm: false
				, is_sms_confirm: false
				, member_name: ''
				, birthdate: ''
				, is_personal: false
			}
			, is_check_id: false
			, is_nice: false
			, is_on_nice: false
			, nice_url: ''
		}
	}
	,computed: {
		isNext: function(){
			const self = this
			const required = ['member_id', 'member_password', 'member_password_confirm', 'member_name', 'member_post', 'member_addr1', 'member_addr2', 'member_tell', 'member_email', 'is_agree']
			let isNext = false
			for(let i = 0; i < required.length; i++){
				if(!self.item[required[i]]){
					isNext = true
					break
				}
			}

			// 비밀번호 유효성 검사
			let isPass = this.rules.password(self.item, 'member_password')
			if(isPass === true){
				self.txtValidCheckPass = ''
			}else{
				self.txtValidCheckPass = isPass
				isNext = true
			}

			// 비밀번호 체크
			if(self.item['member_password_confirm'] != self.item['member_password']){
				isNext = true
			}

			// 이메일 유효성 검사
			if(this.rules.email(self.item, 'member_email')){
				self.txtValidCheckEmail = ''
			}else{
				if(self.item.member_email.length == 0){
					self.txtValidCheckEmail = ''
				}else{
					self.txtValidCheckEmail = '잘못된 형식의 이메일 주소입니다.'
				}

				isNext = true
			}

			if(!this.is_nice){
				isNext = false
			}

			return isNext
		}
		,isGetAdminCode: function(){
			if(this.$route.params.code){
				return true
			}else{
				return false
			}
		}
		,isCheckId: function(){
			if(this.is_check_id){
				return true
			}else{
				return false
			}
		}
		, is_sms_auth: function(){
			let t = true
			if(!this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = false
			}
			return t
		}
		, is_member_tell: function(){
			let t = false
			if(this.item.is_on_sms_confirm && this.item.member_tell.length >= 11){
				t = true
			}
			return t
		}
	}
	,methods: {
		daumPost: function (type) {
			this.$emit('setOverlay')
			this.daumPostUp = true
			this.daumPostType = type
		}
		, addPost: function (call) {

			this.$set(this.item, 'member_post', call.zonecode)
			this.$set(this.item, 'member_addr1', call.address)

			this.daumPostUp = false
			this.$emit('setOverlay')
		}
		,next: async function(){

			try{

				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postJoin'
					,data: this.item
				})
				if(result.success){
					this.toJoinResult()
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		,toJoinResult: function(){
			this.$router.push({ name: 'JoinResult'})
		}
		,toBack: function(){
			this.$router.back()
		}
		, resetSmsAuth: function(){
			this.item.is_on_sms_confirm = false
			this.item.is_sms_confirm = false
			this.item.sms_code = ''
		}
		, postSmsAuth: async function(){
			try{
				this.item.is_on_sms_confirm = false
				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postSmsAuth'
					,data: {
						member_tell: this.item.member_tell
						, admin_code: this.item.admin_code
					}
				})
				if(result.success){
					this.item.is_on_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}
		, postSmsConfirm: async function(){
			try{
				let result = await this.Axios({
					method: 'post'
					,url: '/auth/postSmsConfirm'
					,data: {
						member_tell: this.item.member_tell
						, sms_code: this.item.sms_code
					}
				})
				if(result.success){
					this.item.is_sms_confirm = true
				}else{
					this.$bus.$emit('notify', { type: 'error', message: result.message })
				}
			}catch(e){
				console.log(e.message)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}
		}

		, niceResult: function(e){
			try {
				let domains = process.env.VUE_APP_DOMAIN.split('|')
				domains.push('localhost')
				let t = false
				for(let i = 0; i < domains.length; i++){
					if(e.origin.indexOf(domains[i]) > -1){
						t = true
					}
				}
				if(t){
					let data = e?.data
					if(data && typeof data == 'string'){
						data = JSON.parse(data)
						if(data.type == 'nice_result'){
							if(data.result){
								this.item.member_name = data.data.name
								this.item.member_tell = data.data.phone
								this.item.birthdate = data.data.birthdate
								this.is_nice = true
							}else{
								throw data.message
							}
						}
					}
				}
			}catch (e) {
				this.step = 3
				this.message = e
			}
		}

		, onNice: async function(){
			// this.is_on_confirm = !this.is_on_confirm
			window.open(this.nice_url, 'nice', 'width=320px')
		}

		, checkCode: function(){
			if(!this.$route.params.code){
				this.$bus.$emit('notify', {type: 'error', message: '잘못된 접근입니다.'})
				this.$router.back()
			}
		}
	}
	, destroyed() {
		window.removeEventListener('message', this.niceResult)
	}
	,created: function(){
		this.$emit('setProgram', this.program)

		// this.checkCode()

		window.removeEventListener('message', this.niceResult)
		setTimeout( () => {
			window.addEventListener('message', this.niceResult)
		}, 100)


		let baseUrl = this.$baseUrl

		this.nice_url = baseUrl + '/nice/request'
	}
}
</script>

<style>

</style>